import axios, { AxiosInstance } from 'axios';
import { getAuthToken, getQueryParameters, getUserData } from './api';
import { Board, PaginationParams } from './types';

export class GetMyBoards {
  private axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async getMyBoards({
    page = 1,
    limit = 10,
    offset = 0,
    sort = '-_id',
    search = ''
  }: PaginationParams = {}): Promise<Board[]> {
    const authToken = getAuthToken();
    if (!(authToken && authToken.length)) {
      throw new Error('Need to be authenticated to perform this request');
    }

    const { email } = getUserData();
    const headers = {
      Authorization: `Bearer ${authToken}`
    };

    const query = getQueryParameters({ page, limit, offset, sort, search });
    const url = `/board/byemail/${email}?${query}`;

    try {
      const response = await this.axiosInstance.get(url, { headers });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Request canceled', error.message);
      } else if (!navigator.onLine) {
        console.error('No internet connection');
      } else {
        console.error(error);
      }
      console.error('Error fetching boards:', error);
      throw new Error('Failed to fetch boards');
    }
  }
}
